.progress-container {
    width: 415px;
    display: grid;
    grid-auto-flow: row;
    gap: 30px;
    background-color: #EDEFF1;
    border-radius: 20px;
    padding: 20px 20px 30px;
    margin: 0px auto;
}

.chart-container {
    width: 289px;
    height: 289px;
    position: relative;
    margin: 0 auto 20px;
}

.progress-bar,
.progress-bar-bkg,
.progress-line,
.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.progress-bar {
    width: 97%;
    height: 97%;
}

.progress-bar-bkg {
    width: 97%;
    height: 97%;
    background-color: #E1E4E8;
}

.progress-line {
    width: 100%;
    height: 100%;
}

.progress-bar::after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 60%);
    background-color: #123351;
    border-radius: 50%;
    border: 1px solid #ffffff;
}

.progress-text {
    width: 82%;
    height: 82%;
    display: grid;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.progress-text .number {
    font-size: 97px;
    font-family: 'Waleray', sans-serif;
    font-weight: 900;
    color: #123351;
    line-height: 1;
}

.progress-text .label {
    font-size: 18px;
    font-family: 'Waleray', sans-serif;
    font-weight: 700;
    color: #7A8B9C;
    text-transform: uppercase;
    padding-top: 5px;
    max-width: 150px;
}

.lock-circle {
    width: 21px;
    height: 21px;
    display: grid;
    align-content: center;
    justify-content: center;
    background-color: #123351;
    border-radius: 50%;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 50%);
    border: 2px solid #ffffff;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

/* Bronze */
.progress-container.month-0 .current-rank-color-circle,
.progress-container.month-1 .current-rank-color-circle,
.progress-container.month-2 .current-rank-color-circle {
    background-image: linear-gradient(120deg, #ce8929 0%, #bc7734 28.81%, #f1c192 72.76%, #bf7b39 100%);
}

/* Silver */
.progress-container.month-0 .lock-circle,
.progress-container.month-1 .lock-circle,
.progress-container.month-2 .lock-circle,
.progress-container.month-3 .lock-circle,
.progress-container.month-0 .next-rank-color-circle,
.progress-container.month-1 .next-rank-color-circle,
.progress-container.month-2 .next-rank-color-circle,
.progress-container.month-3 .current-rank-color-circle,
.progress-container.month-4 .current-rank-color-circle,
.progress-container.month-5 .current-rank-color-circle {
    background-image: linear-gradient(120deg, #777 14.79%, #EAEAEA 59.65%, #888 100%);
}

/* Gold */
.progress-container.month-4 .lock-circle,
.progress-container.month-5 .lock-circle,
.progress-container.month-6 .lock-circle,
.progress-container.month-3 .next-rank-color-circle,
.progress-container.month-4 .next-rank-color-circle,
.progress-container.month-5 .next-rank-color-circle,
.progress-container.month-6 .current-rank-color-circle,
.progress-container.month-7 .current-rank-color-circle,
.progress-container.month-8 .current-rank-color-circle,
.progress-container.month-9 .current-rank-color-circle,
.progress-container.month-10 .current-rank-color-circle,
.progress-container.month-11 .current-rank-color-circle {
    background-image: linear-gradient(120deg, #E3CB9C 0%, #C6972C 28.81%, #E3CB9C 72.76%, #C6972C 100%);
}

/* Platinum */
.progress-container.month-7 .lock-circle,
.progress-container.month-8 .lock-circle,
.progress-container.month-9 .lock-circle,
.progress-container.month-10 .lock-circle,
.progress-container.month-11 .lock-circle,
.progress-container.month-12 .lock-circle,
.progress-container.month-6 .next-rank-color-circle,
.progress-container.month-7 .next-rank-color-circle,
.progress-container.month-8 .next-rank-color-circle,
.progress-container.month-9 .next-rank-color-circle,
.progress-container.month-10 .next-rank-color-circle,
.progress-container.month-11 .next-rank-color-circle,
.progress-container.month-12 .current-rank-color-circle {
    background-image: linear-gradient(120deg, #74c4df 0%, #7bc5d3 28.81%, #b7ebf5 72.76%, #7ec7d5 100%);
}

.rank-to-rank {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.rank-to-rank svg {
    width: 20px;
    height: 20px;
}

.rank-box {
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
    background-color: #DEE1E5;
    border-radius: 11px;
    padding: 10px;
    color: #666666;
    align-self: stretch;
    align-content: start;
}

.progress-container.month-12 .rank-box.next-rank,
.progress-container.month-12 .rank-to-rank svg {
    display: none;
}

.progress-container.month-12 .rank-to-rank {
    grid-template-columns: auto;
    justify-content: center;
}

.rank-title {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.current-rank-color-circle,
.next-rank-color-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #123351;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #ffffff;
}

.rank-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    text-transform: uppercase;
}

.rank-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
}

.rank-name {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
}

.rank-benefits {
    font-size: 14px;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    box-sizing: border-box;
    list-style-type: disc;
}

.rank-benefits li {
    padding-bottom: 4px;
}

.cta {
    display: grid;
    grid-auto-flow: row;
    gap: 15px;
    justify-items: center;
    margin: 0 auto;
}

.cta a.cta-button {
    display: grid;
    align-content: center;
    justify-content: center;
    background-color: #123351;
    color: #ffffff;
    border-radius: 25px 25px;
    padding: 16px 30px;
    font-size: 16px;
    font-family: 'Waleray', sans-serif;
    font-weight: 700;
    text-decoration: none;
}

.cta a {
    color: #566574;
    transition: all 0.3s ease-in-out;
}

.cta a:hover {
    color: #123351;
}

.cta a.cta-button:hover {
    background-color: #1B4C79;
    color: #ffffff;
}

.progress-container.month-0 .chart-container .progress-bar::after {
    display: none;
}

.progress-container.month-1 .chart-container .progress-bar,
.progress-container.month-1 .chart-container .progress-line,
.progress-container.month-4 .chart-container .progress-bar,
.progress-container.month-4 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(120deg);
}

.progress-container.month-1 .chart-container .progress-bar,
.progress-container.month-4 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 240deg, #B8C1C9 280deg, #B8C1C9 330deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-1 .chart-container .progress-line,
.progress-container.month-4 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 250deg, rgba(18, 51, 81, 100) 300deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-2 .chart-container .progress-bar,
.progress-container.month-2 .chart-container .progress-line,
.progress-container.month-5 .chart-container .progress-bar,
.progress-container.month-5 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(240deg);
}


.progress-container.month-2 .chart-container .progress-bar,
.progress-container.month-5 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 120deg, #B8C1C9 160deg, #B8C1C9 250deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-2 .chart-container .progress-line,
.progress-container.month-5 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 130deg, rgba(18, 51, 81, 100) 180deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-7 .chart-container .progress-bar,
.progress-container.month-7 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(60deg);
}

.progress-container.month-7 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 300deg, #B8C1C9 320deg, #B8C1C9 330deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-7 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 310deg, rgba(18, 51, 81, 100) 340deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-8 .chart-container .progress-bar,
.progress-container.month-8 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(120deg);
}

.progress-container.month-8 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 240deg, #B8C1C9 290deg, #B8C1C9 310deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-8 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 250deg, rgba(18, 51, 81, 100) 300deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-9 .chart-container .progress-bar,
.progress-container.month-9 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(180deg);
}

.progress-container.month-9 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 180deg, #B8C1C9 230deg, #B8C1C9 280deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-9 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 190deg, rgba(18, 51, 81, 100) 240deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-10 .chart-container .progress-bar,
.progress-container.month-10 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(240deg);
}

.progress-container.month-10 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 120deg, #B8C1C9 160deg, #B8C1C9 210deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-10 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 130deg, rgba(18, 51, 81, 100) 180deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-11 .chart-container .progress-bar,
.progress-container.month-11 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(300deg);
}

.progress-container.month-11 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 60deg, #B8C1C9 100deg, #B8C1C9 170deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-11 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 70deg, rgba(18, 51, 81, 100) 120deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-3 .chart-container .progress-bar,
.progress-container.month-3 .chart-container .progress-line,
.progress-container.month-6 .chart-container .progress-bar,
.progress-container.month-6 .chart-container .progress-line,
.progress-container.month-12 .chart-container .progress-bar,
.progress-container.month-12 .chart-container .progress-line {
    transform: translate(-50%, -50%) rotate(0deg);
}

.progress-container.month-3 .chart-container .progress-bar,
.progress-container.month-6 .chart-container .progress-bar,
.progress-container.month-12 .chart-container .progress-bar {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 0deg, #B8C1C9 50deg, #B8C1C9 140deg, rgba(18, 51, 81, 0.50) 360deg);
}

.progress-container.month-3 .chart-container .progress-line,
.progress-container.month-6 .chart-container .progress-line,
.progress-container.month-12 .chart-container .progress-line {
    background: conic-gradient(from 180deg at 50% 50%, rgba(18, 51, 81, 0.00) 0deg, rgba(18, 51, 81, 0.00) 10deg, rgba(18, 51, 81, 100) 60deg, rgba(18, 51, 81, 100) 360deg);
}

.progress-container.month-3 .lock-circle,
.progress-container.month-6 .lock-circle,
.progress-container.month-12 .lock-circle {
    border-color: #123351;
}

.progress-container.month-3 .lock-circle svg,
.progress-container.month-6 .lock-circle svg,
.progress-container.month-12 .lock-circle svg {
    display: none;
}

.missed-months-alert {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.missed-months-alert p{
    color: #993b26;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}

@media (max-width: 415px) {
    .rank-to-rank {
        grid-template-columns: 1fr;
        align-content: center;
    }

    .rank-to-rank svg {
        justify-self: center;
        transform: rotate(90deg);
    }
}

@media (max-width: 350px) {
    .progress-text .number {
        font-size: 80px;
    }
}