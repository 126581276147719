.main-content.app {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  max-width: 1400px;
  z-index: 2
}

.background-wrapper,
.profile.plain {
  background: #f2f2f2
}

.errorSection {
  z-index: 100;
  position: relative;
  float: left;
  width: -moz-available;
  padding: 0;
  background-color: #fff;
  margin: 40px 40px 0;
  border-radius: 17px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
  border: 2px solid #f9d0b4
}

.errorSection .sectionPageTitle {
  border-bottom: none;
  color: #9a3b26;
  background-color: #f9d0b4;
  padding: 20px;
  border-radius: 15px 15px 0 0;
  font-size: 26px
}

.errorSection .sectionAction,
.errorSection .sectionContent,
.errorSection .sectionSubtext {
  padding: 20px 40px
}

.errorSection .sectionContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.issueList .sectionContent:first-child {
  padding-bottom: 0
}

.sidebar {
  padding: 0 25px 0 0
}

.sidebar .nav {
  list-style: none;
  padding: 0;
  margin: 0
}

.leftSections {
  float: left;
  width: 300px;
  padding: 0 0 80px;
  margin-top: 48px;
  place-self: baseline
}

.leftSections .navSectionDivider {
  border-bottom: 1px solid #e0e1dd;
  color: #9a9b9c;
  font-size: 16px;
  line-height: 1.54;
  margin: 0 0 10px;
  text-align: left;
  width: 100%;
  padding-bottom: 5px;
  letter-spacing: 1px
}

.sidebar .nav li>a.active {
  background-color: #fff;
  border-left: 3px solid #7ab800;
  padding-left: 13px;
  font-weight: 600;
  color: #7ab800;
  border-radius: 0 10px 10px 0
}

.sidebar .nav li>a {
  font-size: 15px;
  line-height: 1.33;
  padding: 15px;
  color: #4d4f53;
  display: block;
  background: none;
  background-color: transparent;
  text-decoration: none;
  font-weight: 400
}

.leftSections nav {
  -webkit-box-shadow: none;
  box-shadow: none
}

.rightSections {
  float: left;
  width: 1000px;
  padding: 30px 0 80px 10px;
  margin-bottom: 60px;
  margin-top: 40px;
  -ms-flex-item-align: start;
  align-self: flex-start
}

.rightSections.side-by-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px
}

.rightSections.side-by-side .card-container {
  min-width: 48%
}

.rightSections .cancel {
  background-color: #fff;
  color: #747679;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%
}

.rightSections .cancel svg {
  position: relative;
  top: 2px;
  margin-left: 5px
}

.rightSections .website-tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.rightSections .website-tile .card-container {
  min-width: 48%
}

.rightSections .website-tile img {
  width: 100%;
  border-radius: 12px 12px 0 0
}

.rightSections .website-tile .card {
  outline: 3px solid #d9dae4;
  outline-offset: -3px
}

.rightSections .website-tile .card .page-actions {
  display: none
}

.rightSections .website-tile .card .tile-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px
}

.rightSections .website-tile .card .social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 30%;
  place-self: flex-end;
  place-items: flex-end
}

.rightSections .website-tile .card .social-share span {
  padding: 0 0 5px;
  color: #9a9b9c
}

.rightSections .website-tile .card .social-share.social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -ms-flex-item-align: end;
  align-self: flex-end
}

.rightSections .website-tile .card .social-share img {
  width: 100%;
  max-width: 32px;
  width: 33px;
  background-color: #007ec3;
  padding: 8px;
  position: relative;
  border-radius: 55px;
  margin: 0 3px
}

.rightSections .website-tile .card .webPageTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.rightSections .website-tile .card .webPageTitle a {
  font-weight: 600
}

.rightSections .website-tile .card .webPageTitle h4 {
  font-size: 26px;
  padding: 10px 0 5px
}

.rightSections .website-tile .card .webPageTitle span {
  font-size: 16px;
  padding: 0
}

.rightSections .website-tile .card .webPageTitle img {
  position: relative;
  top: 2px;
  width: 16px
}

.rightSections .website-tile .card.activated.green {
  outline: 3px solid #7ab800
}

.rightSections .website-tile .card.static .sectionPageTitle {
  padding-bottom: 10px
}

.rightSections .website-tile .card.static .actions {
  padding: 12px 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  border-top: 1px solid #d3d3d3
}

.rightSections .website-tile .card:not(.activated) .sectionPageTitle,
.rightSections .website-tile .card:not(.activated) img {
  opacity: 1%
}

.rightSections .website-tile .card:not(.activated):hover .sectionPageTitle {
  display: none
}

.rightSections .website-tile .card:not(.activated):hover img {
  opacity: 1%
}

.rightSections .website-tile .card:not(.activated):hover .page-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px
}

.rightSections .website-tile .sectionPageTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: none
}

.rightSections .website-tile .status {
  position: relative;
  top: 8px
}

.rightSections .website-tile>* {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 350px;
  flex: 1 1 350px
}

.rightSections .stacked {
  margin-top: 40px
}

.rightSections .ca-total {
  font-weight: 700;
  margin-left: 10px;
  color: #7ab800
}

.rightSections .credit-grid {
  justify-items: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-template-columns: 1fr;
  grid-gap: 20px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.rightSections .credit-grid .credit-grid,
.rightSections .credit-grid .space--between {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.rightSections .credit-grid .quick-input:last-of-type {
  padding-bottom: 40px
}

.rightSections.app {
  padding: 0
}

.rightSections .contentArea {
  margin: 40px 0;
  padding: 0 24px;
}

.rightSections .contentArea.no-margin {
  margin: 0 0 40px
}

.rightSections .contentArea.data .contentHeader {
  padding-bottom: 5px
}

.rightSections .contentArea.data .contentBody {
  padding-bottom: 20px
}

.rightSections .contentArea .contentHeader {
  padding-bottom: 20px;
  font-weight: 700;
  letter-spacing: .02em
}

.rightSections .contentArea .contentBody p {
  color: #9a9b9c
}

.rightSections .contentArea .contentBody img {
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content
}

.rightSections .contentArea .contentBody.last {
  border-bottom: 1px solid #e0e1dd;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.alert-text {
  color: #9a3b26 !important
}

.alert a {
  color: #9a3b26;
}

.warning-text {
  color: #d2a400 !important
}

.rightSections .contentArea .subContent {
  padding-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px
}

.rightSections .contentArea .contentFooter {
  padding-top: 40px;
  text-align: center;
  width: 100%
}

.rightSections .contentArea .contentFooter p {
  color: #9a9b9c;
  font-size: 14px
}

.rightSections .contentArea .contentColumns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  width: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly
}

.rightSections .contentArea .contentColumns ol {
  list-style: auto;
  padding: 0 40px 40px 20px
}

.rightSections .contentArea .contentColumns ol li {
  max-width: 200px
}

.rightSections .contentArea .subContent .progress-steps {
  margin: 0;
  position: relative;
  top: -7px
}

.rightSections .contentArea .subContent .contentBody {
  margin-left: 53px
}

.rightSections .contentArea .subContent .contentBody h4 {
  padding-top: 20px;
  font-weight: 700
}

.rightSections .contentArea .subContent .contentBody ul {
  list-style: disc;
  margin-left: 20px;
  padding: 20px 0
}

.rightSections .contentArea .subContent .contentBody ul li {
  padding-top: 10px
}

.rightSections .sectionPageTitle {
  width: 100%;
  font-size: 26px;
  line-height: 1.5;
  text-align: left;
  padding: 20px 0 30px;
  border-bottom: 1px solid #e0e1dd;
  font-weight: 400
}

.rightSections .sectionPageTitle.pt-0 {
  padding-top: 0
}

.rightSections .sectionPageTitle .wallet {
  float: right;
  width: 120px
}

.rightSections .contentPageTitle {
  width: 100%;
  font-size: 26px;
  line-height: 1.5;
  text-align: left;
  padding: 20px 3% 30px;
  font-weight: 400
}

.rightSections .contentPageTitle.center {
  display: inline-block;
  width: 100%;
  text-align: center
}

.contentSubtext,
.rightSections .sectionPageTitle .sectionSubtext {
  padding-top: 20px;
  color: #9a9b9c;
  font-size: 16px;
  font-size: 1rem
}

.rightSections .sectionDetails {
  padding: 24px;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #e0e1dd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.rightSections .sectionDetails .contentColumns {
  padding-top: 0
}

.rightSections .sectionDetails.centered {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  row-gap: 30px;
  padding: 60px
}

.rightSections .sectionDetails:last-child {
  border-bottom: none
}

.rightSections .sectionDetails .sectionWrapper {
  float: left;
  width: 82%
}

.rightSections .sectionDetails .sectionAction {
  float: left;
  width: 18%;
  text-align: right;
  font-weight: 600;
  color: #007ec3
}

.rightSections .sectionDetails .sectionAction a {
  text-decoration: none
}

.rightSections .sectionDetails .sectionWrapper .sectionTitle {
  float: left;
  width: 33%;
  font-weight: 700;
  padding-right: 20px;
  word-wrap: break-word
}

.rightSections .sectionDetails .sectionWrapper .sectionContent {
  float: left;
  width: 67%;
  word-wrap: break-word
}

.rightSections .sectionDetails .sectionWrapper .sectionSubtext {
  padding: 20px 40px 0 0;
  color: #9a9b9c
}

.col-3 {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row
}

.col,
.col-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal
}

.col {
  margin: 15px auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative
}

.col+.col {
  margin-left: 30px
}

.col+.col.no-last {
  margin-left: auto
}

.col.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center
}

.col.mode {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0
}

.background-wrapper,
body {
  background-color: #fafafa
}

.background-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.page {
  background-color: #fafafa;
  padding-bottom: 40px
}

.main-content>section:last-child {
  padding-bottom: 0
}

.intro-text {
  text-align: center
}

.intro-text h1:only-child {
  margin-bottom: 0
}

.intro-text p {
  font-size: 18px
}

.step-progress-container {
  background: #fff;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: static;
  width: 100%
}

.step-progress-container .back-button,
.step-progress-container .next-button {
  position: fixed;
  bottom: 20px;
  z-index: 10
}

.step-progress-container .next-button {
  right: 20px
}

.step-progress-container .next-button:hover {
  text-decoration: none
}

.step-progress-container .back-button {
  left: 20px
}

.page-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: sticky;
  width: 100%;
  background: hsla(0, 0%, 98%, .6);
  background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fafafa), to(hsla(0, 0%, 98%, 0)));
  background: linear-gradient(0deg, #fafafa 20%, hsla(0, 0%, 98%, 0));
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  padding: 20px
}

.page-actions .back-button,
.page-actions .next-button {
  position: relative
}

.page-actions .next-button:hover {
  text-decoration: none
}

.site-nav {
  background-color: #fff;
  padding: 20px;
  z-index: 100;
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.site-nav .nav-logo {
  width: 100%;
  max-width: 100px;
  height: auto
}

.site-nav a {
  display: block
}

.site-nav ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0
}

.site-nav ul,
.site-nav ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.site-nav ul .text-btn {
  font-size: 16px;
  font-weight: 500;
  color: #4d4f53;
  padding: 10px;
  text-transform: none
}

.site-nav ul .text-btn:hover {
  color: #7ab800
}

.site-nav.expand {
  padding-bottom: 0
}

.site-nav .menu-items-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.site-nav .menu-items-wrapper,
.site-nav .user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.site-nav .user-info {
  margin-left: 20px;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px
}

.site-nav .user-info img {
  width: 40px;
  height: auto;
  border-radius: 50%
}

.site-nav .user-info .user-info-text {
  display: none
}

.site-nav .user-info .user-name {
  font-weight: 700
}

.site-nav .mobile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  position: relative
}

.site-nav .mobile-wrapper>li {
  margin: 0 6px
}

.site-nav .mobile-wrapper li {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.site-nav .mobile-wrapper li:hover>a,
.site-nav .mobile-wrapper li:hover>label,
.site-nav .mobile-wrapper li:hover>svg {
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
  color: #7ab800;
  stroke: #7ab800
}

.site-nav .mobile-wrapper li label {
  cursor: pointer;
  position: relative
}

.site-nav .mobile-wrapper li label:focus {
  outline: none;
  color: #7ab800
}

.site-nav .mobile-wrapper li label svg {
  stroke: #707070;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  top: -2px
}

.site-nav .mobile-wrapper li .sub-menu label:after {
  padding-right: 14px
}

.site-nav .mobile-wrapper li i {
  font-size: 10px;
  margin-left: 5px
}

.site-nav .mobile-wrapper li a {
  padding: 10px 5px;
  color: #4d4f53;
  display: inline-block;
  width: 100%;
  -webkit-transition: color .2s ease;
  transition: color .2s ease
}

.site-nav .mobile-wrapper li input[type=checkbox] {
  display: none
}

.site-nav .mobile-wrapper li input:checked~label svg {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  stroke: #7ab800 !important;
  top: -2px
}

.site-nav .mobile-wrapper li input:checked~.has-submenu.sub-right .sub-menu {
  right: 0;
  left: inherit
}

.site-nav .mobile-wrapper li input:checked~.sub-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: inherit;
  right: inherit;
  opacity: 1;
  -webkit-transition: opacity .1s linear;
  transition: opacity .1s linear;
  overflow: visible
}

.site-nav .mobile-wrapper li input:checked~.sub-menu.sub-members-menu {
  right: 0
}

.site-nav .mobile-wrapper .sub-menu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  background-color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  left: -99999px;
  top: 100%;
  opacity: 0;
  -webkit-transition: left 0s .1s, opacity .1s linear;
  transition: left 0s .1s, opacity .1s linear;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .18);
  box-shadow: 0 0 0 rgba(0, 0, 0, .18);
  overflow: hidden
}

.site-nav .mobile-wrapper .sub-menu li {
  padding: 0 20px;
  border-bottom: 1px solid rgba(154, 155, 156, .25);
  white-space: nowrap;
  width: 100%;
  text-align: center
}

.site-nav .mobile-wrapper .sub-menu li a {
  padding: 15px
}

.site-nav .mobile-wrapper .sub-menu li:last-child {
  border-bottom: 0
}

.site-nav .mobile-wrapper.app {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: -8px;
  position: relative
}

nav.app ul {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start
}

.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.name span {
  -ms-flex-item-align: center;
  align-self: center
}

.name img {
  width: 100px
}

.toggle {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding-top: 10px;
  place-content: flex-end;
  position: absolute;
  top: 20px;
  right: 10px
}

.toggle-ul {
  position: relative;
  top: -20px
}

.toggle img {
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 60px
}

.item.button {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3
}

.item {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 20px;
  padding: 20px 0
}

.item,
.item:first-of-type {
  border-top: 1px solid #c4c4c4
}

.item.last {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 40px
}

.cents,
.item.cent {
  text-align: -webkit-center
}

.active .item {
  display: block
}

.item,
.saveButton {
  display: none
}

.item {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  width: 100%;
  text-align: center
}

.item.first {
  margin-top: 20px
}

.main-content.app.white {
  background-color: #fff;
  padding-top: 0
}

.mpn {
  width: 100%;
  min-height: 100vh;
  text-align: center
}

.mpn li {
  border-bottom: 1px solid #d9dae4;
  padding: 24px
}

.hideMobile,
.hideTablet {
  display: none
}

.hideDesktop {
  display: initial
}

.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.italic {
  font-style: italic;
  font-weight: 400
}

.text-green {
  color: #7ab800
}

.red {
  color: #ba0c2f
}

.digitApp {
  font-size: 12px;
  height: 30px;
  width: 30px;
  padding: 15px;
  margin-right: 15px
}

.digitApp,
.dotApp {
  background-color: #9a3b26;
  color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.dotApp {
  font-size: 10px;
  height: 20px;
  width: 20px;
  padding: 8px;
  top: -2px;
  font-weight: 700
}

.arrowApp {
  stroke: #9a3b26;
  stroke-width: 2px
}

.arrowApp .arrow {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

.arrowApp .arrowDown {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg)
}

.arrowApp.blue {
  stroke: #007ec3
}

.complete-link:hover {
  text-decoration: underline;
  color: #9a3b26
}

a.no-stlye {
  text-decoration: none
}

.missing,
a.no-stlye {
  color: #9a3b26
}

.profileItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.profileItems.incomplete {
  margin-left: 20px
}

.itemsArrow {
  margin-left: auto
}

.itemsArrow.home {
  margin-left: 10px
}

.statusTag {
  background-color: #fff6d7;
  color: #d2a400
}

.statusTag,
.statusTag.blue {
  font-weight: 700;
  padding: 6px 15px 8px;
  border-radius: 17px;
  margin-left: 15px
}

.statusTag.blue {
  background-color: #badafb;
  color: #004165
}

.statusTag.grey {
  background-color: #fafafa;
  color: #9a9b9c
}

.statusTag.grey,
.statusTag.red {
  font-weight: 700;
  padding: 6px 15px 8px;
  border-radius: 17px;
  margin-left: 15px
}

.statusTag.red {
  background-color: #f9d0b4;
  color: #9a3b26
}

.statusTag.green {
  font-weight: 700;
  padding: 6px 15px 8px;
  background-color: #eafad5;
  color: #427730;
  border-radius: 17px;
  margin-left: 15px
}

.statusTag.green-sm {
  background-color: #eafad5;
  color: #7ab800
}

.statusTag.green-sm,
.statusTag.grey-sm {
  padding: 1px 15px 3px;
  margin-bottom: 2px;
  font-size: 14px
}

.statusTag.grey-sm {
  background-color: #9a9b9c;
  color: #fff
}

.statusText {
  font-weight: 300;
  padding: 6px 15px 8px;
  background-color: #fff6d7;
  color: #d2a400;
  border-radius: 17px
}

.statusText .content {
  padding-left: 15px
}

.errorText {
  font-weight: 300;
  padding: 6px 15px 8px;
  background-color: #f9d0b4;
  color: #000;
  border-radius: 17px
}

.errorText .content {
  padding: 15px 0 15px 15px
}

.warningText {
  font-weight: 300;
  padding: 6px 15px 8px;
  background-color: #fff6d7;
  color: #000;
  border-radius: 17px
}

.warningText .content {
  padding: 15px 0 15px 15px
}

.cardBreak {
  border: 1px solid #d9dae4;
  width: 100%;
  margin: 20px 0
}

.upload {
  padding: 40px 0
}

.upload,
.upload.done {
  border: 2px dotted #d9dae4
}

.upload.done {
  padding: 0;
  height: auto
}

.upload.issue {
  border: 2px dotted #9a3b26
}

.profileSection {
  padding: 0 40px
}

.portrait {
  position: relative;
  z-index: 5;
  width: 80px;
  min-height: 80px;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px
}

.portrait .inner-content {
  padding: 0
}

.portrait .inner-content a {
  padding: 0;
  position: relative;
  right: 10px;
  top: 10px
}

.website-img {
  width: 100%;
  border-bottom: 1px solid #9a9b9c;
  margin-bottom: 10px
}

.website .inner-content {
  padding: 0
}

.website-card {
  -webkit-box-shadow: 0 0 0 3pt #d9dae4;
  box-shadow: 0 0 0 3pt #d9dae4;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin-bottom: 0;
  background-color: #fff;
  -webkit-transition: border .2s ease;
  transition: border .2s ease;
  border-radius: 17px
}

.website-card.active {
  -webkit-box-shadow: 0 0 0 3pt #7ab800;
  box-shadow: 0 0 0 3pt #7ab800
}

.website-card.inactive {
  -webkit-box-shadow: 0 0 0 3pt #d9dae4;
  box-shadow: 0 0 0 3pt #d9dae4
}

.websiteSection {
  padding: 0 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: self-end;
  -ms-flex-align: self-end;
  align-items: self-end
}

.websiteSection .title {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3
}

.websiteSection .cost {
  text-align: end;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.cost p {
  margin-top: 4px;
  padding-right: 12%;
  font-size: 14px
}

p.sm {
  font-size: 12px
}

.card.policy {
  background-color: #f9d0b4;
  border: 1px dotted #9a3b26
}

p.indent {
  margin-left: 40px
}

.pb-0 {
  padding-bottom: 0 !important
}

.cursor {
  cursor: pointer
}

.left-line {
  border-left: 1px solid #9a9b9c;
  padding-left: 10px
}

button.accordion.app {
  width: 100%;
  border-radius: 17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  -webkit-transition: border .2s ease;
  transition: border .2s ease;
  border: 3px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  color: #007ec3;
  stroke: #007ec3;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
  padding: 15px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

button.accordion.app+.accordion-content {
  color: #4d4f53;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  padding: 0 40px
}

.card.error {
  border: 2px solid #f9d0b4
}

.card-label.app {
  font-size: 27px;
  border-bottom: 1px solid #747679;
  padding-bottom: 10px
}

.error-title {
  padding: 0 0 40px !important
}

.card-label.app.menu {
  border: none;
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit
}

.card-label.error {
  border-bottom: none;
  color: #9a3b26;
  background-color: #f9d0b4;
  padding: 20px;
  border-radius: 15px 15px 0 0
}

.card-label.left {
  text-align: left
}

.card-label.blue {
  color: #007ec3
}

.fr {
  float: right
}

a.red {
  color: #9a3b26
}

a.red:hover {
  text-decoration: underline
}

a.gold {
  color: #d2a400
}

a.gold:hover {
  text-decoration: underline
}

a.button.error,
button.error {
  background-color: #9a3b26;
  color: #fff
}

.modal.top {
  margin-top: 0
}

.modal.input {
  height: auto
}

.modal.input .input-wrapper {
  width: 230px
}

.modal .inner-content {
  padding: 20px
}

.rotated-image {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg)
}

.mw-80 {
  max-width: 80%
}

.inner-content.max {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content
}

.app-page {
  min-width: 100%
}

iframe .vp-center {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.vimeo {
  max-width: -webkit-fill-available;
  min-width: -moz-available
}

.mw-300 {
  max-width: 300px
}

.doc-img {
  float: left;
  margin: 0 20px 40px 0;
  width: 114px
}

.footer-icon,
.w100 {
  width: 100%;
  padding: 0 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.validator {
  padding: 5px 0 15px
}

.password-wrapper .input-wrapper.tax button {
  top: 30%
}

.thin {
  font-weight: 300
}

.ids {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px
}

.document-approval .flex-40>* {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1
}

.id-img {
  width: 100%
}

button.accordion.info-button.five {
  top: -5px
}

.inner-content.menu-min {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content
}

.mw-176 {
  min-width: 176px
}

.f-cen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.h3-md {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  min-width: -moz-fit-content;
  margin-bottom: 0;
  line-height: 44px
}

button.accordion.info-button.profile {
  background-color: transparent;
  top: -5px;
  border-left: none
}

button.accordion.info-button.profile svg {
  stroke: none;
  -webkit-transform: none;
  transform: none
}

.accordion-content.info-box-card.profile {
  right: -22% !important
}

.helper-text {
  font-size: 14px;
  line-height: 16px;
  color: #747679
}

.card {
  margin-bottom: 0
}

.card.error-highlight {
  -webkit-box-shadow: 0 0 0 2px #9a3b26;
  box-shadow: 0 0 0 2px #9a3b26
}

.card-label {
  margin-bottom: 20px;
  text-transform: capitalize
}

nav.expand {
  padding-bottom: 0;
  -webkit-transition: none;
  transition: none
}

p.wide-desktop {
  max-width: 440px
}

.padding-zero-20 {
  padding: 0 20px
}

.pt-10 {
  padding-top: 10px
}

.text-center {
  text-align: center
}

.mb-40 {
  margin-bottom: 40px
}

.mb-20 {
  margin-bottom: 20px
}

.mt-20 {
  margin-top: 20px
}

form {
  padding: 0
}

a.button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.button .icon,
a.button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.button .icon {
  display: none;
  width: auto;
  stroke: #fff
}

.button .icon,
.icon-left {
  margin-right: 5px
}

.arrow {
  margin-top: 2px
}

.icon .rotate-90-left {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: center;
  transform-origin: center
}

.icon .rotate-90-right {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: center;
  transform-origin: center
}

.main-content .hide {
  height: 0;
  opacity: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease
}

.main-content .reveal {
  height: 100%;
  width: 100%;
  opacity: 1
}

.main-content .reveal.top-error {
  position: fixed;
  top: 128px;
  right: 40px;
  width: 510px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 99999999
}

.main-content .reveal.profile-error {
  position: relative;
  top: 0;
  left: 0
}

.rotate-90-left.arrow {
  margin-top: 3px
}

.input-wrapper .helper-text {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9a9b9c
}

.input-wrapper input,
section {
  width: 100%
}

section {
  padding-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.intro-text p:last-child,
section>:last-child {
  margin-bottom: 0
}

.page {
  background-color: #f2f2f2;
  margin: 0 auto;
  border-radius: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0
}

.main-content,
.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%
}

.main-content {
  padding: 40px 20px;
  position: relative
}

.form--content .form-intro p {
  margin-bottom: 0
}

.inner-content.fixed {
  position: fixed;
  margin-top: 10px
}

.form-max {
  width: 100%;
  max-width: 335px
}

@media (min-width:450px) {
  .v-link {
    margin: 20px 0;
    font-weight: 800
  }
}

@media (min-width:650px) {

  .site-nav .mobile-wrapper li.has-submenu.sub-right:focus-within>.sub-menu,
  .site-nav .mobile-wrapper li.has-submenu.sub-right:hover>.sub-menu {
    right: inherit;
    left: inherit
  }

  .site-nav .mobile-wrapper li.has-submenu:focus-within>.sub-menu,
  .site-nav .mobile-wrapper li.has-submenu:hover>.sub-menu {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    left: 0;
    opacity: 1;
    -webkit-transition: opacity .1s linear;
    transition: opacity .1s linear;
    overflow: visible
  }
}

@media (min-width:768px) {
  .rightSections .credit-grid {
    grid-template-columns: auto 1fr
  }

  body {
    background-color: #f3f8fc
  }

  .background-wrapper {
    background: url(../assets/images/somaderm-waterline.jpg) top;
    background-size: cover;
    background-attachment: fixed
  }

  .background-wrapper:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: hsla(0, 0%, 100%, .5);
    z-index: 0
  }

  .page {
    padding-bottom: 20px
  }

  .step-progress-container {
    border-radius: 17px 17px 0 0;
    z-index: 10
  }

  .step-progress-container .back-button,
  .step-progress-container .next-button {
    position: relative;
    bottom: auto
  }

  .page-actions {
    border-radius: 0 0 17px 17px;
    position: sticky;
    padding: 20px 40px;
    background: hsla(0, 0%, 100%, .6);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fff), to(hsla(0, 0%, 100%, 0)));
    background: linear-gradient(0deg, #fff 20%, hsla(0, 0%, 100%, 0))
  }

  .site-nav.expand {
    padding-bottom: 20px
  }

  .site-nav {
    position: relative;
    width: 100%;
    -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
  }

  .site-nav .nav-logo {
    max-width: 120px
  }

  .site-nav ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .site-nav ul .link-login {
    width: 100%;
    height: 100%;
    opacity: 1
  }

  .site-nav .user-info img {
    width: 45px
  }

  .site-nav .user-info .user-info-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
  }

  nav.app {
    position: relative;
    padding: 20px;
    width: 100%;
    -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
  }

  nav ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  nav ul .link-login {
    width: 100%;
    height: 100%;
    opacity: 1
  }

  nav ul img {
    width: 100px
  }

  .logo img {
    content: url(../assets/svg/logo.svg) !important;
    min-width: 160px
  }

  .hideMobile {
    display: initial
  }

  .hideDesktop {
    display: none
  }

  .card--container.app {
    max-width: 50%;
    -ms-flex-item-align: baseline;
    align-self: baseline;
    place-items: self-start
  }

  .card.flex-fill {
    min-width: 31em
  }

  .app.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
    -ms-flex-direction: initial;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 20px
  }

  .global-alert.id {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    left: 0;
    top: auto;
    width: 100%;
    position: absolute;
    bottom: auto
  }

  nav.expand {
    padding-bottom: 20px
  }

  .button .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  nav {
    padding: 20px 100px;
    width: 100%;
    -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
  }

  .page {
    margin: 40px;
    width: 90%;
    max-width: 800px;
    border-radius: 17px;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, .2);
    box-shadow: 0 0 25px rgba(0, 0, 0, .2);
    background-color: #fff
  }

  .page.app {
    max-width: 1240px
  }

  .main-content {
    padding: 20px 40px 60px
  }

  .card--container {
    padding-top: 5px
  }
}

@media (min-width:768px) {
  .menu {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  li.logo {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    padding-top: 10px
  }

  .item.button {
    width: auto;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: block
  }

  .item.cent {
    text-align: -webkit-center
  }

  .toggle {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    place-content: flex-end
  }

  .item {
    display: none
  }

  .menu li.button a {
    padding: 10px 15px;
    margin: 5px 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
  }

  .button a {
    background: #fff
  }

  .button.secondary {
    border: 0
  }

  .button.secondary a {
    background: transparent
  }

  .button a:hover {
    text-decoration: none
  }

  .button:not(.secondary) a:hover {
    background: grey;
    border-color: grey
  }

  .name {
    position: absolute;
    top: 18px;
    left: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }
}

@media (min-width:960px) {

  .site-nav a,
  .toggle-ul {
    display: none !important
  }

  .hideTablet {
    display: initial
  }

  .hideDesktop {
    display: none
  }
}

@media (min-width:960px) {
  .menu {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    background: none
  }

  .logo {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    max-width: 300px
  }

  li.item {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    display: none;
    width: auto;
    border-top: 0;
    margin-left: 10px
  }

  .button {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
  }

  .name {
    position: absolute;
    top: 18px;
    left: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .name span {
    -ms-flex-item-align: center;
    align-self: center
  }

  .toggle {
    display: none
  }

  .saveButton {
    display: contents;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto
  }
}

@media (min-width:1024px) {
  .left-line {
    margin-left: 20px
  }

  .card-label.app {
    text-align: left
  }

  .card--container.app.flexing {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    max-width: 75%
  }

  .card--container.app.flexing-1 {
    max-width: 75%
  }

  .card--container.app.flexing.full {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .desk-max {
    max-width: 50%
  }

  .card.flexer {
    margin-right: 40px
  }

  .card.flexer.limit {
    max-width: 75%
  }

  .full-card {
    display: inline-block;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: inherit
  }

  .card--container.app.menu {
    max-width: 30%
  }

  .vimeo {
    min-width: -webkit-fill-available;
    min-width: -moz-available;
    min-height: 22em
  }

  .v-link {
    text-align: center
  }

  .center-data {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .short-row {
    max-height: 48px
  }

  .center-data p {
    margin-top: 1em
  }

  .center-data.two {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end
  }

  .p-subtext {
    width: 30%
  }

  .cd1,
  .cd2 {
    width: 33%
  }

  .cd2 {
    text-align: left
  }

  .cd3 {
    width: 33%;
    text-align: right
  }

  .cd4 {
    width: 33%;
    text-align: left
  }

  .email-sel {
    margin-left: 51%
  }

  .page {
    margin: 60px
  }
}

@media (max-width:1380px) {
  .leftSections {
    width: 250px
  }

  .rightSections {
    width: 77%
  }

  .rightSections.app {
    width: 100%
  }
}

@media (max-width:1023px) {
  .rightSections .sectionDetails .contentColumns {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .rightSections .sectionDetails {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .rightSections .sectionDetails .sectionWrapper .sectionContent {
    width: 100%;
    padding: 20px 0
  }

  .rightSections .sectionDetails .sectionAction {
    width: 100%;
    text-align: left
  }

  .rightSections .sectionDetails .sectionWrapper,
  .rightSections .sectionDetails .sectionWrapper .sectionTitle {
    width: 100%
  }

  .left-line {
    margin-top: 20px
  }

  .desk-max {
    max-width: 335px
  }

  .email-sel {
    margin-left: 40px
  }
}

@media (max-width:980px) {
  .leftSections {
    width: 400px
  }

  .rightSections {
    width: 100%;
    padding: 0;
    margin-top: 0
  }

  .doc-img {
    float: none;
    width: 114px;
    display: block;
    margin: 0 auto
  }

  .doc-btn {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .doc-title {
    text-align: center;
    font-size: 18px
  }

  .ids {
    display: block
  }

  .document-approval .flex-40 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  #id-back {
    margin-top: 40px
  }
}

@media (max-width:768px) {
  .rightSections {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .card-container:nth-of-type(2) {
    margin-top: 40px
  }

  .rightSections .contentArea .contentColumns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .rightSections .contentArea .contentColumns iframe {
    max-width: 100%
  }

  .rightSections .contentArea .contentColumns .rightCol {
    padding-top: 20px
  }

  .rightSections .contentArea .subContent .contentBody {
    margin-left: 0
  }

  .rightSections .sectionPageTitle .wallet {
    float: none;
    position: relative;
    top: 14px
  }

  .col-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .col {
    margin: 15px 10px
  }

  .col+.col {
    margin-left: 10px
  }

  nav.app {
    border-bottom: 1px solid #747679
  }

  .name {
    position: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    font-size: 18px
  }

  .menu,
  .site-nav ul.menu {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .page.app {
    max-width: 1240px
  }

  .main-content {
    padding: 20px 40px 60px
  }

  .main-content .reveal.top-error {
    width: 100%
  }

  .main-content .reveal.profile-error {
    position: fixed;
    top: 86%;
    left: 0;
    max-height: 14%
  }
}

@media (max-width:768px) {
  .logo {
    display: none
  }
}

@media screen and (max-width:600px) {
  .site-nav .burger-menu a:not(:first-child) {
    display: none
  }

  .site-nav .burger-menu a.icon {
    float: right;
    display: block
  }

  .site-nav .burger-menu.responsive {
    position: relative
  }

  .site-nav .burger-menu.responsive .icon {
    position: absolute;
    right: 0;
    top: 0
  }

  .site-nav .burger-menu.responsive a {
    float: none;
    display: block;
    text-align: left
  }
}

.profile-info__section {
  display: flex;
  grid-template-columns: auto 1fr;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  position: relative;
  padding: 24px 0;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #e0e1dd;
  margin-bottom: 0;
}

.profile-info__section h3 {
  margin: 0;
}

.profile-info__section::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 100%;
  height: 1px;
  width: 100%;
  background-color: white;
}